import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import "./Climate.css";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchClimateRiskAction,
  selectClimateRisk,
} from "../risk/climateRiskSlice";
import { selectAddress } from "../address/addressSlice";
import { ResultBlockType, ClimateRiskType } from "../../types";
import { climateRiskItems, scaleLevels, scaleLevelType } from "../../constants";

import SearchResultBlock from "../../components/Block";
import ClimateItem from "../../components/ClimateItem";
import RisksKey from "../../components/RiskKey/index";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import OLMap from "../map/OLMap";

const ToggleButtonWhite = styled(ToggleButton)({
  width: "70px",
  color: "white",
  fontSize: "16px",
  borderColor: "#6b9dc6",
  "&.Mui-selected:hover": {
    backgroundColor: "#6b9dc6",
  },
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "#6b9dc6",
  },
});

const DISABLED = -1

export default function ClimateRiskResultBlock({
  id,
  iconType,
  title,
}: ResultBlockType) {
  const dispatch = useAppDispatch();
  const address = useAppSelector(selectAddress);
  const climatRisk = useAppSelector(selectClimateRisk);
  const { enqueueSnackbar } = useSnackbar();
  const [catnatList, setCatnatList] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [rcpSelection, setRCPSelection] = useState<string>("rcp26");
  const { t } = useTranslation();
  const [riskSelected, setRiskSelected] = useState<ClimateRiskType | null>();

  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] =useState<google.maps.LatLngLiteral>({
    lat: 48.866667,
    lng: 2.333333,
  });

  useEffect(() => {
    setRiskSelected(null);
    if (
      !address ||
      !address.value ||
      !address.value.latitude ||
      !address.value.longitude
    ) {
      return;
    }
    setCenter({ lat: address.value.latitude, lng: address.value.longitude });
    setZoom(16);
  }, [address]);

  useEffect(() => {
    if (!address?.value) {
      return;
    }
    dispatch(fetchClimateRiskAction(address?.value?.placeId));
  }, [address?.value, dispatch]);

  // useEffect(() => {
  //   if (!address?.value) {
  //     return;
  //   }
  //   dispatch(resetAction());
  //   if (riskSelected?.id) {
  //     getApiFunctionForRisk(
  //       riskSelected?.id,
  //       address?.value?.placeId,
  //       dispatch
  //     );
  //   }
  // }, [address?.value, riskSelected?.id, dispatch]);

  useEffect(
    function () {
      if (climatRisk.error) {
        enqueueSnackbar("L'interrogation des risques climatiques a échoué", {
          variant: "error",
        });
      }
      setCatnatList(climatRisk?.value?.risks?.catnats);
      setEvents(climatRisk?.value?.risks?.events);
      const fistRiskAvailable = climateRiskItems.find(
        (risk) => getScaleLevel(risk, rcpSelection)?.num !== -1
      );
      setRiskSelected(fistRiskAvailable);
      // if (climatRisk?.value) {
      //   const { catnats, ...otherRisk } = climatRisk.value.risks
      //   setZoneRisk({
      //     ...otherRisk
      //   })
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [climatRisk, enqueueSnackbar]
  );

  const setTooltipInfo = (scaleLevel: scaleLevelType | undefined, type: string): string => {
    if (!scaleLevel || !scaleLevel.info) {
      return "";
    }
    return scaleLevel.info[type];
  };

  const scaleLevelByRisk: Record<string, (content: any, rcp: any) => scaleLevelType | undefined> = {
    flood: (content) => {
      // if (!content.waterMask) {
      //     return scaleLevel.no_risk.num;
      // } else {
      //     return content.waterDepth ? getLowerFloodLevel(content.waterDepth) : null;
      // }
      const matchingScaleLevel = scaleLevels[content.riskLevel] || scaleLevels[DISABLED];
      return matchingScaleLevel
    },
    pluvialFlood: (content) => {
      // if (!content.waterMask) {
      //     return scaleLevel.no_risk.num;
      // } else {
      //     return content.waterDepth ? getLowerFloodLevel(content.waterDepth) : null;
      // }
      const matchingScaleLevel = scaleLevels[content.riskLevel] || scaleLevels[DISABLED];
      return matchingScaleLevel;
    },
    drought: (content) => {
      const matchingScaleLevel = scaleLevels[content.riskLevel] || scaleLevels[DISABLED];
      return matchingScaleLevel;
    },
    fire: (content) => {
      const matchingScaleLevel = scaleLevels[content.riskLevel] || scaleLevels[DISABLED];
      return matchingScaleLevel;
    },
    hailStorm: (content) => {
      const matchingScaleLevel = scaleLevels[content.riskLevel] || scaleLevels[DISABLED];
      return matchingScaleLevel;
    },
    heavyRain: (content, rcp = null) => {
      const matchingScaleLevel = scaleLevels[content[rcp]] || scaleLevels[DISABLED];
      return matchingScaleLevel;
    },
    wind: (content, rcp = null) => {
      const matchingScaleLevel = scaleLevels[content[rcp]] || scaleLevels[DISABLED];
      return matchingScaleLevel;
    },
    submersion: (content) => {
      // content level has value from 1 to 5, scaleLevel has value from 0 to 4
      const matchingScaleLevel = scaleLevels[content.riskLevel - 1] || scaleLevels[DISABLED];
      return matchingScaleLevel;
    },
    coastalErosion: (content) => {
      // content level has value from 1 to 5, scaleLevel has value from 0 to 4
      const matchingScaleLevel = scaleLevels[content.riskLevel] || scaleLevels[DISABLED];
      return matchingScaleLevel;
    },
  };

  const getScaleLevel = (item: ClimateRiskType, rcp: string): scaleLevelType | undefined => {
    const content =
      climatRisk.value &&
      climatRisk.value.risks[item.id] &&
      climatRisk.value.risks[item.id].properties;
    const isValue = climatRisk.status === "idle";
    const type = item.id;
    if (!content || !isValue) return scaleLevels[DISABLED];

    const scaleLevelFunction = scaleLevelByRisk[type];
    return scaleLevelFunction(content, rcp);
  };

  const renderClimateItems = () => {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", padding: "1rem 0" }}
      >
        {climateRiskItems.map((item: ClimateRiskType, index) => {
          const scaleLevelValue = getScaleLevel(item, rcpSelection);
          return (
            <div
              key={`climate_risk_item_${index}`}
              className={`ClimateRiskItem ${
                item === riskSelected ? "selected" : ""
              }`}
              onClick={() => setRiskSelected(item)}
            >
              <ClimateItem
                type={item.id}
                catnatTypes={item.catnatTypes}
                title={t(item.label)}
                content={
                  climatRisk.value &&
                  climatRisk.value.risks[item.id] &&
                  climatRisk.value.risks[item.id].properties
                }
                warning={catnatList}
                events={events}
                isValue={climatRisk.status === "idle"}
                rcp={rcpSelection}
                scaleLevel={scaleLevelValue}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newRCPSelection: string
  ) => {
    if (newRCPSelection !== null) {
      setRCPSelection(newRCPSelection);
    }
  };

  const renderRCPSelectionForm = () => (
    <>
      <span style={{ padding: "0 10px" }}>RCP</span>
      <ToggleButtonGroup
        color="primary"
        value={rcpSelection}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButtonWhite value="rcp26">2.6</ToggleButtonWhite>
        <ToggleButtonWhite value="rcp45">4.5</ToggleButtonWhite>
        <ToggleButtonWhite value="rcp85">8.5</ToggleButtonWhite>
      </ToggleButtonGroup>
    </>
  );

  return (
    <SearchResultBlock
      className="ClimateSearchBlock"
      title={title}
      iconType={iconType}
      id={id}
      rightComponent={renderRCPSelectionForm}
    >
      {renderClimateItems()}
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div className="mapRisk">
          {/* <Map
            mapId="riskMap"
            center={center}
            zoom={zoom}
            style={{ height: "600px", margin: "16px 0" }}
            selectedRisk={riskSelected?.id}
            selectedRcp={rcpSelection}
            zoneRiskInfo={zoneRisk}
            grayscale
          /> */}
          <OLMap
            style={{ height: "600px", margin: "16px 0" }}
            selectedRisk={riskSelected?.id}
            selectedRcp={rcpSelection}
            center={center}
            zoom={zoom}
          />
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          {riskSelected && (
            <div className="tooltip">
              <span className="tooltipLabel">{t(riskSelected.label)}</span>
              <p className="tooltipInfo">
                {t(
                  setTooltipInfo(
                    getScaleLevel(riskSelected, rcpSelection),
                    riskSelected.id
                  )
                )}
              </p>
            </div>
          )}
          <div className="RisksKeyWrapper">
            <RisksKey scaleLevel={scaleLevels} />
          </div>
        </div>
      </div>
    </SearchResultBlock>
  );
}
