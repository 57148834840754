import "./index.css";

import Icon from "../Icon";
import StepProgressBar from "../StepProgressBar";

import { scaleLevelType } from "../../constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PropsType {
  type: string;
  catnatTypes: string[];
  title: string;
  content: any | undefined;
  warning: any[] | undefined;
  events: any[] | undefined;
  isValue: boolean;
  rcp: string;
  scaleLevel?: scaleLevelType;
}

const containerIconStyle: Record<string, string> = {
  justifyContent: "center",
  alignItems: "center",
  width: "3rem",
  height: "3rem",
  borderRadius: "50%",
  cursor: "pointer",
};

export default function ClimateItem({
  type,
  catnatTypes,
  title,
  content,
  warning,
  events,
  isValue,
  rcp,
  scaleLevel,
}: PropsType): JSX.Element {
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [event, setEvent] = useState<any>(null); // setisEvent will be put later when correctly used
  const { t } = useTranslation();

  useEffect(() => {
    let alert: boolean = false;
    let event: boolean = false;
    if (warning) {
      for (let i = 0; i < warning?.length; i++) {
        if (
          catnatTypes.filter((value) => warning[i].type.includes(value)).length
        ) {
          alert = true;
          break;
        }
      }
    }
    if (events && events.length !== 0 && type === "coastalErosion")
      event = events[0];
    setIsWarning(alert);
    setEvent(event);
  }, [warning, content, events, catnatTypes, type]);

  return (
    <div className="ClimateItemWrapper">
      <div className="RiskAlert">
        <div className="RiskBarIcon">
          <Icon
            type={`${type}_svg`}
            size="1.75rem"
            containerStyle={{
              ...containerIconStyle,
              backgroundColor: scaleLevel?.color,
            }}
          />
          <div>
            <span className="RiskTitle">{title}</span>
            <StepProgressBar step={scaleLevel!.num} color={scaleLevel!.color!} />

            {content || content === null ? (
              <div className="RiskLabel">{t(scaleLevel!.label!)}</div>
            ) : (
              // blank line when we dont have this climate risk
              <div className="RiskLabel">
                <br />
              </div>
            )}
          </div>
          {(!!isWarning) && (
            <div className="Warning">
              <Icon type="warning" />
            </div>
          )}
          {(!!event) && (
            <div className="Event">
              <a href={event.url} target="_blank" rel="noreferrer">
                <Icon type="document" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
